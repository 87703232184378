import React, { Component } from "react";
import "../Styles/Footer.less";

export default class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <p>Copyright © 2020 GCD all rights reserved.</p>
            </div>
        );
    }
}
