import { work } from "./types";

export const HightLights = [
    {
        id: "we_will_share_association",
        name: "Religious",
        class: "religious",
        coverPhoto: "https://i.imgur.com/K8fggeU.jpg",
    },
    {
        id: "metrowalk_shopping_center",
        name: "Department Stores",
        class: "retail",
        coverPhoto: "https://i.imgur.com/olcWslx.jpg",
    },
    {
        id: "general_electric_intl",
        name: "Workplace",
        class: "workplace",
        coverPhoto: "https://i.imgur.com/2yctxq9.jpg",
    },
    {
        id: "syntrend_shopping_center",
        name: "Retail centers",
        class: "retail",
        coverPhoto: "https://i.imgur.com/lCb2tIp.jpg",
    },
    {
        id: "genuine_group_gene21+",
        name: "Residential",
        class: "residential",
        coverPhoto: "https://i.imgur.com/j8PMVp8.jpg",
    },
    {
        id: "tao_garden_hotel",
        name: "Hospitality Interiors",
        class: "hospitality",
        coverPhoto: "https://i.imgur.com/Y0YvRb9.jpg",
    },
];

export const classes = [
    { title: "Workplace", id: "workplace" },
    { title: "Financial Services", id: "financial_services" },
    { title: "Hospitality", id: "hospitality" },
    { title: "Retail", id: "retail" },
    { title: "Healthcare", id: "healthcare" },
    { title: "Residential", id: "residential" },
    { title: "Religious", id: "religious" },
];

export const works: work[] = [
    {
        id: "edwards_lifesciences_taiwan",
        name: "EDWARDS LIFESCIENCES TAIWAN",
        class: "workplace",
        fileRoot: "https://i.imgur.com",
        photos: ["dZSi0JS.jpg", "4s6fMWr.jpg", "o7YgM9e.jpg", "US4g6Kn.jpg", "AESIaQ0.jpg", "FS6O3y4.jpg"],
    },
    {
        id: "residence",
        name: "RESIDENCE",
        class: "residential",
        fileRoot: "https://i.imgur.com",
        photos: ["nfuJUXO.jpg", "AkSmG2q.jpg", "DeBvGZD.jpg", "T8yk5Ji.jpg", "6EJnX5W.jpg"],
    },
    {
        id: "we_will_share_association",
        name: "WE WILL SHARE ASSOCIATION",
        class: "religious",
        fileRoot: "https://i.imgur.com",
        photos: ["K8fggeU.jpg", "cLqFTUp.jpg", "fz2isHd.jpg", "iFBLMsm.jpg"],
    },
    {
        id: "metrowalk_shopping_center",
        name: "METROWALK SHOPPING CENTER",
        class: "retail",
        fileRoot: "https://i.imgur.com",
        photos: [
            "olcWslx.jpg",
            "WauqzfS.jpg",
            "Easn9qn.jpg",
            "2jGIzbi.jpg",
            "EvLHpsN.jpg",
            "39ve1AY.jpg",
            "iDmlnEL.jpg",
            "XU2Z3fx.jpg",
            "YNxbWpf.jpg",
            "83JAwAS.jpg",
            "KkRTZJA.jpg",
            "anrzYQb.jpg",
        ],
    },
    {
        id: "shan_shan_cha_tea_house",
        name: "SHAN SHAN CHA–TEA HOUSE",
        class: "retail",
        fileRoot: "https://i.imgur.com",
        photos: ["rUifIOG.jpg", "AkBf2C1.jpg", "HRT43pW.jpg", "T2VU43y.jpg"],
    },
    {
        id: "syntrend_shopping_center",
        name: "SYNTREND SHOPPING CENTER",
        class: "retail",
        fileRoot: "https://i.imgur.com",
        photos: ["NRhYcTT.jpg", "IOpAcA6.jpg", "XPhkhVM.jpg", "lCb2tIp.jpg", "9i1z8l2.jpg", "jdNIIBm.jpg", "1Mtgtl8.jpg", "77KPc8u.jpg"],
    },
    {
        id: "genuine_group_gene21+",
        name: "GENUINE GROUP GENE21+",
        class: "residential",
        fileRoot: "https://i.imgur.com",
        photos: [
            "j8PMVp8.jpg",
            "ailuogK.jpg",
            "WSBIPzM.jpg",
            "6vcDVnm.jpg",
            "lZXAQWS.jpg",
            "3hy5jsg.jpg",
            "skHpKDl.jpg",
            "Hju3Lj3.jpg",
            "pLjkq1p.jpg",
            "F3ExrRm.jpg",
        ],
    },
    {
        id: "hyde_park_one",
        name: "HYDE PARK ONE",
        class: "residential",
        fileRoot: "https://i.imgur.com",
        photos: ["vDu5oX9.jpg", "F5sSYCY.jpg", "F8Whn4R.jpg"],
    },
    {
        id: "residential",
        name: "RESIDENTIAL",
        class: "residential",
        fileRoot: "https://i.imgur.com",
        photos: ["g8H8pGL.jpg", "YTk0L8U.jpg", "Dl4Z065.jpg", "0beWbAu.jpg", "o9kjjXu.jpg"],
    },
    {
        id: "jih_sun_international_bank",
        name: "JIH SUN INTERNATIONAL BANK",
        class: "financial_services",
        fileRoot: "https://i.imgur.com",
        photos: ["WLpxU99.jpg", "aU7ACyG.jpg", "mPWvb8L.jpg", "Aw6Dhl0.jpg", "xxgxj5J.jpg"],
    },
    {
        id: "investec_asset_management",
        name: "INVESTEC  ASSET  MANAGEMENT",
        class: "financial_services",
        fileRoot: "https://i.imgur.com",
        photos: ["26m19x9.jpg", "XsQXVxt.jpg", "vLxx8Lj.jpg", "foPQkNb.jpg"],
    },
    {
        id: "shin_kong_hospital_tomo_therapy",
        name: "SHIN-KONG HOSPITAL TOMO THERAPY",
        class: "healthcare",
        fileRoot: "https://i.imgur.com",
        photos: ["G9ZontA.jpg", "P7gcGzs.jpg", "GX1atXl.jpg"],
    },
    {
        id: "sheraton_shinchu_hotel",
        name: "SHERATON SHINCHU HOTEL",
        class: "hospitality",
        fileRoot: "https://i.imgur.com",
        photos: ["2hMyum5.jpg", "tAgMrwN.jpg"],
    },
    {
        id: "tao_garden_hotel",
        name: "TAO GARDEN HOTEL",
        class: "hospitality",
        fileRoot: "https://i.imgur.com",
        photos: ["Y0YvRb9.jpg", "yqxyLox.jpg", "YjvMtOA.jpg", "0A07VXw.jpg", "WRP8lGw.jpg"],
    },
    {
        id: "transpak_equipment_corp",
        name: "TRANSPAK EQUIPMENT CORP",
        class: "workplace",
        fileRoot: "https://i.imgur.com",
        photos: ["gDEh1tQ.jpg", "TdOBe7J.jpg", "JGkNAvk.jpg", "Z7DveAV.jpg", "ysMcB0X.jpg"],
    },
    {
        id: "vorwerk",
        name: "VORWERK",
        class: "workplace",
        fileRoot: "https://i.imgur.com",
        photos: ["0ngqgNk.jpg", "mrSZghs.jpg", "XPkcPMd.jpg", "2epXHhc.jpg"],
    },
    {
        id: "fonterra_brand",
        name: "FONTERRA BRAND",
        class: "workplace",
        fileRoot: "https://i.imgur.com",
        photos: ["NCfm6we.jpg", "wdNKmnq.jpg"],
    },
    {
        id: "general_electric_intl",
        name: "GENERAL ELECTRIC INTL",
        class: "workplace",
        fileRoot: "https://i.imgur.com",
        photos: ["nkzwh20.jpg", "2yctxq9.jpg", "pZLpLF7.jpg", "FHQ2QTu.jpg", "GCvCB5f.jpg", "WHXXweB.jpg", "Ulij09D.jpg"],
    },
    {
        id: "mars_incorporated",
        name: "MARS Incorporated",
        class: "workplace",
        fileRoot: "https://i.imgur.com",
        photos: ["P9fpOOY.jpg", "CdG3BqQ.jpg"],
    },
    {
        id: "china_trust",
        name: "CHINA TRUST",
        class: "workplace",
        fileRoot: "https://i.imgur.com",
        photos: ["q1PSxi5.jpg"],
    },
    {
        id: "amgen_taiwan",
        name: "AMGEN TAIWAN",
        class: "workplace",
        fileRoot: "https://i.imgur.com",
        photos: [
            "F29IKkM.jpg",
            "NsNa5jd.jpg",
            "s92H26G.jpg",
            "92LY7JQ.jpg",
            "YLU4Ts0.jpg",
            "T27SgTw.jpg",
            "26RbWG0.jpg",
            "07SASTW.jpg",
            "qaKSRPV.jpg",
            "gWcbSUP.jpg",
            "glQOfZO.jpg",
            "iKfuu5G.jpg",
            "NRgo7lm.jpg",
            "ezPncWC.jpg",
            "uZPddMS.jpg",
            "x851nFJ.jpg",
            "3CoC21R.jpg",
            "F56Bode.jpg",
            "f6Bixda.jpg",
            "glvvXmA.jpg",
            "x8Ng9bG.jpg",
            "uAhhCgm.jpg",
            "b0mSu3R.jpg",
            "oIgAfwD.jpg",
            "2E0ty5z.jpg",
        ],
    },
    {
        id: "infosys",
        name: "INFOSYS",
        class: "workplace",
        fileRoot: "https://i.imgur.com",
        photos: ["FVUVst7.jpg", "NlgioHh.jpg", "TiIwLxH.jpg"],
    },
    {
        id: "cushman_&_wakefield",
        name: "CUSHMAN & WAKEFIELD",
        class: "workplace",
        fileRoot: "https://i.imgur.com",
        photos: ["zszpg0j.jpg", "MxKNV86.jpg", "5ctxO5E.jpg"],
    },
    {
        id: "pc_securities_financial_group",
        name: "PC SECURITIES FINANCIAL GROUP",
        class: "workplace",
        fileRoot: "https://i.imgur.com",
        photos: ["Qr48DWH.jpg", "JFx9o7D.jpg", "kpeWFTj.jpg"],
    },
    {
        id: "volkswagen_financial_services",
        name: "VOLKSWAGEN FINANCIAL SERVICES",
        class: "workplace",
        fileRoot: "https://i.imgur.com",
        photos: ["eNjGcB7.jpg", "C0JVuOH.jpg"],
    },
    {
        id: "hewlett_packard_enterprise",
        name: "HEWLETT PACKARD ENTERPRISE",
        class: "workplace",
        fileRoot: "https://i.imgur.com",
        photos: ["cFgRkmP.jpg"],
    },
    {
        id: "general_electric_intl_hk",
        name: "GENERAL ELECTRIC INTL HK",
        class: "workplace",
        fileRoot: "https://i.imgur.com",
        photos: ["0Ku0xhT.jpg", "SFnSE0Z.jpg"],
    },
];
